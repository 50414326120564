import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Button, Stack } from '@mui/material';
import palette from 'src/theme/palette';
import { FONT_ROBOTO } from 'src/theme/typography';

export const StackStyled = styled(Stack)`
	padding-left: 20px;
`;

export const GreetingsFirstLine = styled(Typography)({
	paddingTop: '30px',
	paddingBottom: '7px',
	fontSize: '18px',
});

export const GreetingsSecondLine = styled(Typography)({
	paddingBottom: '30px',
	fontSize: '18px',
});

export const TitleStyled = styled(Typography)(() => ({
	marginBottom: '13px',
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const BoxDocumentsStyle = styled(Box)(() => ({
	height: '100%',
	marginTop: '20px',
	display: 'block',
}));

// export const BoxDocumentsInnerStyle = styled(Box)(() => ({
// 	display: 'flex',
// 	gap: '44px',
// 	marginTop: '20px',
// 	flexWrap: 'wrap',
// }));

export const TypographyDocumentsStyle = styled(Typography)(() => ({
	marginBottom: '13px',
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const TypographyPropertyViewStyle = styled(Typography)(() => ({
	marginBottom: '13px',
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const StackPropertyViewStyle = styled(Stack)(() => ({
	paddingLeft: '20px',
	height: '100%',
}));

export const TypographyPropertyDeleteStyle = styled(Typography)(() => ({
	marginBottom: '16px',
	fontWeight: 700,
	fontSize: '18px',
	lineHeight: '20px',
}));

export const TypographyDescriptionPropertyDeleteStyle = styled(Typography)(() => ({
	marginBottom: '40px',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '19px',
	color: 'custom.bidyText',
}));

export const ButtonPropertyDeleteStyle = styled(Button)(() => ({
	width: '171px',
}));

export const TypographyMessagePropertyDeleteStyle = styled(Typography)(() => ({
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '19px',
	color: palette.light.custom.bidyText,
}));

export const StackPropertyCreateStyle = styled(Stack)(() => ({
	marginBottom: '22px',
	'.MuiOutlinedInput-notchedOutline': {
		borderColor: palette.light.custom.bidyText,
	},
}));

export const LoadingButtonStyled = styled(LoadingButton)(() => ({
	width: '180px',
	fontSize: '16px',
	margin: '34px 0 0 auto',
}));

export const Title = styled('p')({
	fontFamily: FONT_ROBOTO,
	fontWeight: 700,
	fontSize: '16px',
	lineHeight: '19px',
	textTransform: 'capitalize',
	color: '#BDBDBD',
});
