import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { fileDownloader } from 'src/api/questionnaire/documents/utils';

const storage = getStorage();

export const downloadUserProofOfId = async (name: string, path: string) => {
	const proofRef = ref(storage, `gs://${process.env.REACT_APP_PUBLIC_ASSETS_BUCKET}/${path}`);

	const proofUrl = await getDownloadURL(proofRef).catch(() => null);

	if (!proofUrl) return null;

	await fileDownloader(proofRef)(`${name} - ID, Address and AML check.pdf`);

	return true;
};
