import { useSelector } from 'react-redux';
import { TypographyStatus } from 'src/components/cards/QuestionnaireCard/styles';
import { selectTransactionOverview } from 'src/redux/slices/transaction';

const TransactionStateLabel = () => {
	const transaction = useSelector(selectTransactionOverview);

	if (!transaction.state || transaction.state === 'LIVE') {
		return <TypographyStatus sx={{ color: '#20BC39', background: '#CDF3D5' }}>Live</TypographyStatus>;
	}

	if (transaction.state === 'ARCHIVED') {
		return <TypographyStatus sx={{ color: '#20BC39', background: '#CDF3D5' }}>Completed</TypographyStatus>;
	}

	if (transaction.state === 'STANDBY') {
		return <TypographyStatus sx={{ color: '#697885', background: '#EDEDED' }}>Standby</TypographyStatus>;
	}

	if (transaction.state === 'DEMO') {
		return <TypographyStatus sx={{ color: '#303838', background: '#00fffb' }}>Demo</TypographyStatus>;
	}

	if (transaction.state === 'FALL_THROUGH') {
		return <TypographyStatus sx={{ color: 'white', background: '#303838' }}>Defunct (Fall-through)</TypographyStatus>;
	}

	return <TypographyStatus sx={{ color: 'white', background: '#303838' }}>Defunct</TypographyStatus>;
};

export default TransactionStateLabel;
