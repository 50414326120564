import { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router';

import LoadingScreen from 'src/components/LoadingScreen';
import { clearProperty, getProperty, selectPropertySlice } from 'src/redux/slices/property';
import { getPropertyQuestionnaire } from 'src/redux/slices/propertyQuestionnaire';
import { getRoomsThunk } from 'src/redux/slices/rooms';
import {
	clearTransaction,
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	selectTransactionOverviewField,
} from 'src/redux/slices/transaction';
import { getTransactionQuestionnaire } from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';

const TransactionLayout: FC<PropsWithChildren> = ({ children }) => {
	const { id = '' } = useParams();
	const transaction = useSelector(selectTransactionOverviewField);
	const { isLoading } = useSelector(selectPropertySlice);

	useEffect(() => {
		dispatch(getTransactionOverviewThunk({ id, withLoading: true }));
		dispatch(getTransactionParticipantsThunk({ id, withLoading: true }));
		dispatch(getTransactionQuestionnaire({ id }));
		dispatch(getRoomsThunk());

		return () => {
			dispatch(clearTransaction());
			dispatch(clearProperty());
		};
	}, [id]);

	useEffect(() => {
		if (transaction.data?.propertyId) {
			dispatch(getProperty({ id: transaction.data.propertyId }));
			dispatch(getPropertyQuestionnaire({ id: transaction.data.propertyId }));
		}
	}, [transaction.data?.propertyId]);

	if (!transaction.data && !transaction.isLoading) return <Navigate to={PATH_DASHBOARD.root} />;
	if (transaction.isLoading || isLoading) return <LoadingScreen />;

	return <>{children}</>;
};

export default TransactionLayout;
