import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import {
	getTransactionQuestionnaire,
	selectTransactionQuestionnaireSlice,
} from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { selectPermissions } from 'src/redux/slices/permissions';
import { Box } from '@mui/material';
import Progress from 'src/components/Progress';
import QuestionnairePropertyCard from 'src/components/cards/QuestionnairePropertyDetailsCard/QuestionnairePropertyCard';
import QuestionnaireOwnershipCard from 'src/components/cards/QuestionnaireOwnershipCard/QuestionnaireOwnershipCard';
import QuestionnaireServicesCard from 'src/components/cards/QuestionnaireServicesCard/QuestionnaireServicesCard';
import QuestionnaireEnvironmentCard from 'src/components/cards/QuestionnaireEnvironmentCard/QuestionnaireEnvironmentCard';
import QuestionnaireBuildingWorksCard from 'src/components/cards/QuestionnaireBuildingWorksCard/QuestionnaireBuildingWorksCard';
import QuestionnaireRightsCard from 'src/components/cards/QuestionnaireRightsCard/QuestionnaireRightsCard';
import QuestionnaireGuaranteeCard from 'src/components/cards/QuestionnaireGuaranteeCard/QuestionnaireGuaranteeCard';
import SellerFinanceCard from 'src/components/cards/SellerFinanceCard';
import QuestionnaireBuyerCard from 'src/components/cards/QuestionnaireBuyerCard/QuestionnaireBuyerCard';
import { QuestionnaireLiabilitiesCard } from 'src/components/cards/QuestionnaireLiabilitiesCard/QuestionnaireLiabilitiesCard';
import QuestionnaireMovingOutCard from 'src/components/cards/QuestionnaireMovingOutCard/QuestionnaireMovingOutCard';
import { GiftorFinanceCard } from 'src/components/cards/GiftorFinanceCard/GiftorFinanceCard';
import { QuestionnaireSellContent } from 'src/components/cards/QuestionnaireSellContent/QuestionnaireSellContent';
import QuestionnaireMovingInCard from 'src/components/cards/QuestionnaireMovingInCard/QuestionnaireMovingInCard';
import { selectUser } from 'src/redux/slices/auth';
import { QuestionnaireStatus, VerificationSessionStatus } from 'src/types';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { selectTransactionOverview, selectTransactionParticipants } from 'src/redux/slices/transaction';
import ProprietorFinanceCard from 'src/components/cards/ProprietorFinanceCard';
import { QuestionnaireFittingsAndContents } from 'src/components/cards/QuestionnaireFittingsAndContentsCard/QuestionnaireFittingsAndContents';
import Category from 'src/pages/transactions/participants/components/Category';
import { TransactionQuestionnairesWrapper } from './styles';

const TransactionQuestions = () => {
	const { id = '' } = useParams();
	const participants = useSelector(selectTransactionParticipants);
	const permissions = useSelector(selectPermissions);
	const user = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const { loading: isPropertyQuestionnaireLoading } = useSelector(selectPropertyQuestionnaireSlice);
	const { loading: isTransactionQuestionnaireLoading, fullQuestionnaire: transactionQuestionnaire } = useSelector(
		selectTransactionQuestionnaireSlice,
	);

	const personalDetails = transactionQuestionnaire?.personal_details?.[user.uid];

	useEffect(() => {
		dispatch(getTransactionQuestionnaire({ id }));
	}, [id]);

	useEffect(() => {
		if (
			user.verificationStatus !== VerificationSessionStatus.VERIFIED &&
			user.isPrincipal &&
			!transaction.isBuyerViewer
		) {
			dispatch(openModal({ name: modals.completeIdChecks }));

			return;
		}

		if (personalDetails && personalDetails.personal?.status !== QuestionnaireStatus.DONE) {
			dispatch(openModal({ name: modals.completePersonalDetails }));
		}
	}, [user.verificationStatus]);

	if (isPropertyQuestionnaireLoading || isTransactionQuestionnaireLoading || !permissions.questionnaire.isInitialized) {
		return (
			<Box height='100%' width='100%' display='flex' alignItems='center' justifyContent='center'>
				<Progress />
			</Box>
		);
	}

	return (
		<TransactionQuestionnairesWrapper>
			{transaction.isInstrument ? (
				<>
					<Category title='Property' sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
						<QuestionnaireOwnershipCard />
						<QuestionnaireGuaranteeCard />
					</Category>
					<Category title='Borrower' sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
						<ProprietorFinanceCard />
					</Category>
				</>
			) : (
				<>
					<Category title='Property' sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
						<QuestionnairePropertyCard />
						<QuestionnaireOwnershipCard />
						<QuestionnaireServicesCard />
						<QuestionnaireEnvironmentCard />
						<QuestionnaireBuildingWorksCard />
						<QuestionnaireRightsCard />
						<QuestionnaireGuaranteeCard />
						<QuestionnaireLiabilitiesCard />
					</Category>
					<Category title='Sell-Side' sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
						<SellerFinanceCard />
						<QuestionnaireMovingOutCard />
						<QuestionnaireFittingsAndContents />
						{transactionQuestionnaire?.fitting_contents?.items_for_sale?.items_for_sale_yn && (
							<QuestionnaireSellContent />
						)}
					</Category>
					<Category title='Buy-Side' sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
						<QuestionnaireBuyerCard />
						<QuestionnaireMovingInCard />
						{(participants?.participants.buyers ?? [])
							.filter((b) => b.relationship === 'giftor')
							.map((g) => (
								<GiftorFinanceCard key={g.uid} participant={g} />
							))}
					</Category>
				</>
			)}
		</TransactionQuestionnairesWrapper>
	);
};

export default TransactionQuestions;
