import { TransactionState } from './types';

export const transactionState = {
	live: 'LIVE',
	demo: 'DEMO',
	standBy: 'STANDBY',
	defunct: 'DEFUNCT',
	fallThrough: 'FALL_THROUGH',
	archived: 'ARCHIVED',
} as const;

export const inactiveTransactionStates = [transactionState.defunct, transactionState.archived];

export const labelByTransactionState: Record<TransactionState, string> = {
	ARCHIVED: 'Completed',
	DEFUNCT: 'Defunct',
	FALL_THROUGH: 'Defunct (Fall-through)',
	DEMO: 'Demo',
	LIVE: 'Live',
	STANDBY: 'Standby',
};

export const transactionStateOptions = Object.values(transactionState).map((state) => ({
	key: state,
	name: labelByTransactionState[state],
}));
