/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	addDocumentsFolder,
	deleteDocument,
	deleteDocumentsFolder,
	getDocumentsOverview,
	updateDocumentsFolder,
	uploadDocument,
} from 'src/api';
import type { Payload as DeleteDocumentsFolderPayload } from 'src/api/documents/delete-documents-folder';
import type { Payload as UpdateDocumentsFolderPayload } from 'src/api/documents/update-documents-folder';
import type { Payload as AddDocumentsFolderPayload } from 'src/api/documents/add-documents-folder';
import type { Payload as GetDocumentsOverviewPayload } from 'src/api/documents/get-documents-overview';
import type { Payload as UploadDocumentPayload } from 'src/api/documents/upload-document';
import type { Payload as DeleteDocumentPayload } from 'src/api/documents/delete-document';
import { dispatch, RootState } from '../store';
import { Document, Documents, DocumentsState } from '../types/documents';
import { selectProperty } from './property';

export const selectDocumentsSlice = (state: RootState) => state.documents;
export const selectDocumentsFolderId = (state: RootState) => state.documents.folderId;
export const selectDocumentsField = (state: RootState) => state.documents.documents;
export const selectDocuments = (state: RootState) => state.documents.documents.data as Documents;

const initialState: DocumentsState = {
	folderId: 'root',
	documents: { data: null, isInitialized: false },
};

export const getDocumentsOverviewThunk = createAsyncThunk<
	Documents,
	Omit<GetDocumentsOverviewPayload, 'propertyId' | 'folderId'> & { withLoading?: boolean; folderId?: string }
>('documents-overview/get', async (payload, { getState }) => {
	const state = getState() as RootState;
	const property = selectProperty(state);

	if (!payload.folderId) {
		const currentFolderId = selectDocumentsFolderId(state);

		payload.folderId = currentFolderId;
	}

	return await getDocumentsOverview({ ...payload, propertyId: property.id } as GetDocumentsOverviewPayload);
});

export const deleteDocumentsFolderThunk = createAsyncThunk<void, Omit<DeleteDocumentsFolderPayload, 'propertyId'>>(
	'documents-folder/delete',
	async (payload, { getState }) => {
		const state = getState() as RootState;
		const property = selectProperty(state);

		await deleteDocumentsFolder({ ...payload, propertyId: property.id });
	},
);

export const updateDocumentsFolderThunk = createAsyncThunk<void, Omit<UpdateDocumentsFolderPayload, 'propertyId'>>(
	'documents-folder/update',
	async (payload, { getState }) => {
		const state = getState() as RootState;
		const property = selectProperty(state);

		await updateDocumentsFolder({ ...payload, propertyId: property.id });
	},
);

export const addDocumentsFolderThunk = createAsyncThunk<void, Omit<AddDocumentsFolderPayload, 'propertyId'>>(
	'documents-folder/add',
	async (payload, { getState }) => {
		const state = getState() as RootState;
		const property = selectProperty(state);

		await addDocumentsFolder({ ...payload, propertyId: property.id });
	},
);

export const uploadDocumentThunk = createAsyncThunk<void, Omit<UploadDocumentPayload, 'propertyId' | 'type' | 'name'>>(
	'document/upload',
	// eslint-disable-next-line consistent-return
	async (payload, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState;
			const property = selectProperty(state);

			await uploadDocument({ ...payload, propertyId: property.id });
		} catch (e) {
			// eslint-disable-next-line consistent-return
			return rejectWithValue(e);
		}
	},
);

export const deleteDocumentThunk = createAsyncThunk<void, Omit<DeleteDocumentPayload, 'propertyId'>>(
	'document/delete',
	async (payload, { getState }) => {
		const state = getState() as RootState;
		const property = selectProperty(state);
		const folderId = selectDocumentsFolderId(state);

		await deleteDocument({ ...payload, propertyId: property.id });

		dispatch(getDocumentsOverviewThunk({ location: payload.location, folderId, withLoading: true }));
	},
);

const propertySlice = createSlice({
	name: 'documents',
	initialState,
	reducers: {
		updateCurrentDocumentsFolder: (state, action: PayloadAction<string>) => {
			state.folderId = action.payload;
			state.documents.isInitialized = false;
		},
		goToBreadcrumb: (state, { payload }: PayloadAction<string>) => {
			if (!state.documents.data) return state;

			const breadcrumbIndex = state.documents.data.breadcrumbs.findIndex((b) => b.folderId === payload);

			if (breadcrumbIndex !== -1) {
				state.documents.data.breadcrumbs = state.documents.data.breadcrumbs.slice(0, breadcrumbIndex + 1);
			}

			state.folderId = payload;
			state.documents.isInitialized = false;

			return state;
		},
		goInsideDocument: (state, { payload }: PayloadAction<Document & { parent?: Document | null }>) => {
			if (!state.documents.data) return state;

			const newBreadcrumbs = [...state.documents.data.breadcrumbs];

			if (payload.parent) {
				if (newBreadcrumbs.at(-1)?.folderId !== payload.parent.id) {
					newBreadcrumbs.push({ name: payload.parent.name, folderId: payload.parent.id });
				}
			}

			newBreadcrumbs.push({ name: payload.name, folderId: payload.id });

			state.documents.data.breadcrumbs = newBreadcrumbs;
			state.folderId = payload.id;

			return state;
		},
		setAreDocumentsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.documents.isInitialized = !payload;
		},
		clearDocuments: (state) => {
			state.documents.data = null;
			state.documents.isInitialized = false;
			state.folderId = 'root';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getDocumentsOverviewThunk.pending, (state, { meta }) => {
			state.documents.isInitialized = !meta.arg.withLoading;
		});

		builder.addCase(getDocumentsOverviewThunk.fulfilled, (state, { payload, meta }) => {
			if (state.folderId !== meta.arg.folderId) return state;

			state.documents.isInitialized = true;
			state.documents.data = payload;

			return state;
		});

		builder.addCase(getDocumentsOverviewThunk.rejected, (state, { error }) => {
			state.documents.isInitialized = true;
			state.documents.error = error;
		});

		builder.addCase(uploadDocumentThunk.rejected, (state) => {
			state.documents.isInitialized = true;
		});
	},
});

export const {
	updateCurrentDocumentsFolder,
	goToBreadcrumb,
	goInsideDocument,
	clearDocuments,
	setAreDocumentsLoading,
} = propertySlice.actions;

export default propertySlice.reducer;
