import useLoading from 'src/hooks/useLoading';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import modals from 'src/constants/modals';
import { useSelector } from 'react-redux';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { FormProvider, useForm } from 'react-hook-form';
import { getTransactionRef } from 'src/utils/firebase';
import { updateDoc } from 'firebase/firestore';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFAutocomplete } from 'src/components/hook-form';
import { dispatch } from 'src/redux/store';
import { ConfirmationModal } from '../../common';
import { UpdateTransactionStateForm } from './types';
import { transactionState, transactionStateOptions } from './constants';
import { updateTransactionStateValidationSchema } from './validation';

const Content = () => {
	const transaction = useSelector(selectTransactionOverview);
	const form = useForm<UpdateTransactionStateForm>({
		resolver: yupResolver(updateTransactionStateValidationSchema),
		defaultValues: { state: transaction.state ?? transactionState.live },
	});
	const { isLoading, setIsLoading } = useLoading();

	const handleSubmit = async (data: UpdateTransactionStateForm) => {
		setIsLoading(true);

		const transactionRef = getTransactionRef(transaction.id);

		await updateDoc<unknown>(transactionRef, { state: data.state, statusUpdatedAt: new Date() });

		await dispatch(getTransactionOverviewThunk({ id: transaction.id })).unwrap();

		handleCloseModal();
		setIsLoading(false);
	};

	const filteredTransactionStateOptions = transactionStateOptions.filter((option) => {
		if (option.key === 'ARCHIVED') return transaction.tracker.completion.status === 'completed';

		return true;
	});

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Box>
					<div>
						<Box display='flex' flexDirection='column' gap='10px'>
							<RHFAutocomplete
								name='state'
								label='Select a state'
								options={filteredTransactionStateOptions}
								valueAttribute='key'
							/>
						</Box>
						<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
							<LoadingButton
								onClick={handleCloseModal}
								variant='outlined'
								size='large'
								loading={isLoading}
								sx={{ textTransform: 'unset' }}
							>
								Cancel
							</LoadingButton>
							<LoadingButton
								variant='contained'
								size='large'
								type='submit'
								loading={isLoading}
								sx={{ textTransform: 'unset' }}
							>
								Confirm
							</LoadingButton>
						</Box>
					</div>
				</Box>
			</form>
		</FormProvider>
	);
};

const UpdateTransactionStateModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '500px', overflow: 'visible' }}
		modalName={modals.updateTransactionState}
		title='SET TRANSACTION STATE'
		withButtons={false}
		description={<Content />}
	/>
);

export default UpdateTransactionStateModal;
