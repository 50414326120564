export const TERMS_OF_USE = 'terms-of-use';
export const PRIVACY_POLICY = 'privacy-policy';
export const ACCEPTABLE_USE_POLICY = 'acceptable-use-policy';

const modals = {
	estateAgentDelete: 'estateAgentDelete',
	sellerSolicitorDelete: 'sellerSolicitorDelete',
	buyerSolicitorDelete: 'buyerSolicitorDelete',
	sellerSolicitorModal: 'sellerSolicitorModal',
	buyerSolicitorModal: 'buyerSolicitorModal',
	estateAgentModal: 'estateAgentModal',
	withdrawSale: 'withdrawSale',
	abandonDeal: 'abandonDeal',
	transferDeal: 'transferDeal',
	buyerInvite: 'buyerInvite',
	abandonPurchase: 'abandonPurchase',
	buyerSolicitor: 'buyerSolicitor',
	transactionUserDelete: 'transactionUserDelete',
	offerModal: 'offerModal',
	askPrice: 'askPrice',
	requiredAskPrice: 'requiredAskPrice',
	sellerRelationship: 'sellerRelationship',
	inviteSeller: 'inviteSeller',
	inviteBuyer: 'inviteBuyer',
	withdrawBuyer: 'withdrawBuyer',
	makeBuyer: 'makeBuyer',
	documentMultipleUpload: 'documentMultipleUpload',
	invitation: 'invitation',
	promoteBuyer: 'promoteBuyer',
	confirmPrice: 'confirmPrice',
	generateDocument: 'generateDocument',
	assignTitle: 'assignTitle',
	searchTitle: 'searchTitle',
	titleAssignmentConfirmation: 'titleAssignmentConfirmation',
	getOfficialCopies: 'getOfficialCopies',
	availableDocuments: 'availableDocuments',
	saleConfirmation: 'saleConfirmation',
	withdrawOffer: 'withdrawOffer',
	acceptAskPrice: 'acceptAskPrice',
	acceptAskPriceConfirmation: 'acceptAskPriceConfirmation',
	dropProperty: 'dropProperty',
	deleteTransaction: 'deleteTransaction',
	counterOffer: 'counterOffer',
	counterOfferReceived: 'counterOfferReceived',
	viewerCounterOfferReceived: 'viewerCounterOfferReceived',
	counterOfferAccepted: 'counterOfferAccepted',
	offerRejected: 'offerRejected',
	viewerOfferRejected: 'viewerOfferRejected',
	offerAccepted: 'offerAccepted',
	acceptOffer: 'acceptOffer',
	acceptCounteroffer: 'acceptCounteroffer',
	addProperty: 'addProperty',
	addTransaction: 'addTransaction',
	addRemortgage: 'addRemortgage',
	completePersonalDetails: 'completePersonalDetails',
	completeIdChecks: 'completeIdChecks',
	support: 'support',
	fullVersion: 'fullVersion',
	confirmOwnership: 'confirmOwnership',
	confirmIdentity: 'confirmIdentity',
	confirmPayment: 'confirmPayment',
	identityVerificationRequest: 'identityVerificationRequest',
	identityVerification: 'identityVerification',
	verificationRequired: 'verificationRequired',
	photosCarousel: 'photosCarousel',
	privacyPolicy: 'privacyPolicy',
	termsAndConditions: 'termsAndConditions',
	acceptableUsePolicy: 'acceptableUsePolicy',
	transactionQrCode: 'transactionQrCode',
	onboardingSaleConfirmation: 'onboardingSaleConfirmation',
	onboardingPurchaseConfirmation: 'onboardingPurchaseConfirmation',
	onboardingRemortgageConfirmation: 'onboardingRemortgageConfirmation',
	addDocumentsFolder: 'addDocumentsFolder',
	updateDocumentsFolder: 'updateDocumentsFolder',
	deleteDocumentsFolder: 'deleteDocumentsFolder',
	deleteDocument: 'deleteDocument',
	abandonTransaction: 'abandonTransaction',
	manageEmailChangeRequest: 'manageEmailChangeRequest',
	cancelPropertyOnboarding: 'cancelPropertyOnboarding',
	freezeTransaction: 'freezeTransaction',
	demoTransaction: 'demoTransaction',
	undemoTransaction: 'undemoTransaction',
	updateTransactionState: 'updateTransactionState',
	unfreezeTransaction: 'unfreezeTransaction',
	unbinTransaction: 'unbinTransaction',
	archiveTransaction: 'archiveTransaction',
	propertyAlreadyOnMarket: 'propertyAlreadyOnMarket',
	giftorWelcome: 'giftorWelcome',
	userVerificationFailed: 'userVerificationFailed',
	userNameConfirmationError: 'userNameConfirmationError',
	userAddressConfirmationError: 'userAddressConfirmationError',
	forceTitleAssignment: 'forceTitleAssignment',
	signDeed: 'signDeed',
	enterSignature: 'enterSignature',
	moveTask: 'moveTask',
	deleteTask: 'deleteTask',
};

export default modals;
