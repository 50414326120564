import { TrackerStepName, TrackerStepStatus } from 'src/types';

export const convertTrackerStepStatusToText = (status: TrackerStepStatus) => {
	switch (status) {
		case 'notStarted':
			return 'Not Started';
		case 'inProgress':
			return 'In Progress';
		case 'pending':
			return 'Pending';
		case 'completed':
			return 'Completed';
		case 'error':
			return 'Error';
		default:
			return 'N/A';
	}
};

export const convertTrackerStepNameToLabel = (step: TrackerStepName) => {
	switch (step) {
		case 'buyerDueDiligence':
			return 'Buyer Due Diligence';
		case 'completion':
			return 'Completion';
		case 'exchange':
			return 'Exchange';
		case 'listing':
			return 'Listing';
		case 'saleAgreed':
			return 'Sale Agreed';
		case 'sellerDueDiligence':
			return 'Seller Due Diligence';
		default:
			return 'N/A';
	}
};
