import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Iconify from 'src/components/Iconify';

export const makeTooltipStyles = makeStyles({
	tooltip: { fontSize: '15px' },
});

export const TrackerStepWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.grey[200],
	borderRadius: '50%',
	width: '75px',
	height: '75px',
	fontSize: '27px',
	fontWeight: 700,
	color: theme.palette.grey[600],
}));

export const TrackerStepSeparator = styled(Box)(({ theme }) => ({
	height: '3px',
	width: '65px',
	background: theme.palette.grey[400],
}));

export const TrackerStepETA = styled(Typography)<{ isCompleted?: boolean }>(({ theme, isCompleted }) => ({
	textAlign: 'center',
	position: 'absolute',
	minWidth: '150px',
	minHeight: '50px',
	top: -30,
	color: isCompleted ? theme.palette.success.main : theme.palette.grey[500],
	fontWeight: 700,
}));

export const TrackerStepTitle = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	position: 'absolute',
	minWidth: '150px',
	minHeight: '50px',
	bottom: -60,
	color: theme.palette.grey[800],
	fontWeight: 700,
}));

export const TrackerStepIcon = styled(Iconify)(() => ({
	width: '65px',
	height: '65px',
}));
