import { useSelector } from 'react-redux';
import { selectTransactionTracker } from 'src/redux/slices/transaction';
import { Box, Typography } from '@mui/material';
import Progress from 'src/components/Progress';
import { TransactionSummaryTable } from '../../styles';
import { trackerSteps } from '../../constants';
import TrackerSection from './components/TrackerSection';

const Tracker = () => {
	const tracker = useSelector(selectTransactionTracker);

	return (
		<TransactionSummaryTable title='Tracker' minWidth='1200px' sx={{ position: 'relative' }}>
			{tracker.error ? (
				<Typography variant='subtitle1' color='red' textAlign='center' fontSize='20px'>
					{tracker.error.message}
				</Typography>
			) : (
				<>
					{trackerSteps.map((s) => (
						<TrackerSection key={s} step={s} />
					))}
				</>
			)}
			{!tracker.isInitialized && (
				<Box py='75px' position='absolute' height='100%' width='100%' bgcolor='white' sx={{ opacity: 0.6 }}>
					<Progress />
				</Box>
			)}
		</TransactionSummaryTable>
	);
};

export default Tracker;
