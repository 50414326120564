import { styled } from '@mui/material/styles';
import { Box, Card } from '@mui/material';

export const TransactionQuestionnairesWrapper = styled(Box)`
	padding: 32px 25px;
	display: flex;
	flex-direction: column;
	gap: 25px;
`;

export const TransactionGeneralWrapper = styled(Box)`
	display: grid;
	column-gap: 40px;
	row-gap: 40px;
	@media screen and (min-width: 768px) {
		grid-template-columns: minmax(max-content, 620px);
	}
	@media screen and (min-width: 1600px) {
		grid-template-columns: minmax(max-content, 620px) minmax(max-content, 620px);
	}
`;

export const EmptyCard = styled(Card)`
	height: 500px;
	width: 100%;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;
