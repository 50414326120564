import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { getTransactionTasksThunk, selectTransactionTasks } from 'src/redux/slices/transaction';
import { Typography, Box } from '@mui/material';
import Progress from 'src/components/Progress';
import palette from 'src/theme/palette';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import Task from './components/Task';
import { TasksWrapper } from './styled';

const TasksSection = () => {
	const tasks = useSelector(selectTransactionTasks);

	useEffect(() => {
		dispatch(getTransactionTasksThunk({ withLoading: true }));
	}, []);

	return (
		<PropertyGeneralAddressCard
			sx={{ maxHeight: '620px', overflowY: 'auto', pb: 0, border: `1px solid ${palette.light.info.light}` }}
		>
			<TypographyAddressTitle mb='20px'>YOUR TASKS</TypographyAddressTitle>
			<TasksWrapper>
				{tasks.isInitialized ? (
					<Box width='100%' mb='25px'>
						{tasks.data.length ? (
							<Box>
								<Box display='flex' flexDirection='column' gap='10px'>
									{tasks.data.map(({ user, tasks: userTasks }) => (
										<div key={user.id}>
											<Typography sx={{ cursor: 'default' }} variant='link'>
												@{user.knownAs ?? user.givenNames}
											</Typography>
											:
											<Box pl='35px'>
												<Box
													component='ol'
													display='flex'
													flexDirection='column'
													gap='5px'
													sx={{ listStylePosition: 'outside' }}
												>
													{userTasks.map((task) => (
														<li key={task.id}>
															<Task {...task} user={user} />
														</li>
													))}
												</Box>
											</Box>
										</div>
									))}
								</Box>
							</Box>
						) : (
							<b>There are currently no pending tasks.</b>
						)}
					</Box>
				) : (
					<Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%' py='50px'>
						<Progress zoom={0.7} />
					</Box>
				)}
			</TasksWrapper>
		</PropertyGeneralAddressCard>
	);
};

export default TasksSection;
