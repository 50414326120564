import {
	styled,
	Box,
	Container,
	Stepper,
	Tabs,
	Typography,
	TableCell as MuiTableCell,
	TableRow as MuiTableRow,
	TableHead as MuiTableHead,
} from '@mui/material';
import { alpha } from '@mui/system';
import { CSSProperties } from 'react';

export const SHADOW = { boxShadow: '0px 1px 1px #F1F1F1, 0px 4px 22px #D6D6D6' };

export const LabelBadgeSx = (isExist: boolean) => ({
	...(isExist && {
		'& .MuiBadge-badge': { right: '12px', top: '8px' },
		paddingRight: '30px',
	}),
	fontFamily: '"Roboto", sans-serif',
	fontSize: '16px',
	fontWeight: 400,
});

export const ContainerStyle = styled(Container)(() => ({
	marginLeft: '0',
	paddingRight: '20px !important',
	height: '100%',
}));

export const BoxStyle = styled(Box)(() => ({
	display: 'flex',
	gap: '20px',
	flexDirection: 'column',
	justifyContent: 'center',
}));

export const TypographyLayoutStyle = styled(Typography)(() => ({
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const TabsStyle = styled(Tabs)(() => ({
	margin: '20px 0 30px 0',
}));

export const StyledStepper = styled(Stepper)({
	MuiStepConnector: {
		root: {
			borderColor: '#36B37E',
		},
	},
});

export const TypoMain = styled(Typography)(({ theme }) => ({
	fontSize: '16px',
	fontWeight: 400,
	color: theme.palette.grey[800],
}));

export const TypoMainBold = styled(TypoMain)({
	fontSize: '23px',
	fontWeight: 700,
});

export const TypoSecondary = styled(TypoMain)({
	fontSize: '18px',
	fontWeight: 700,
});

export const TooltipFirstLine = styled(Typography)({
	paddingTop: '30px',
	paddingBottom: '7px',
	fontSize: '18px',
});

export const TooltipSecondLine = styled(Typography)({
	paddingBottom: '30px',
	fontSize: '18px',
});

export const GreetingsLink = styled('span')(({ theme }) => ({
	color: theme.palette.primary.main,
	textDecoration: 'underline',
	cursor: 'pointer',
}));

export const TableCell = styled(MuiTableCell)<{
	minWidth?: number;
	maxWidth?: number;
	wordBreak?: CSSProperties['wordBreak'];
}>(({ minWidth, maxWidth, wordBreak }) => ({
	...(minWidth && { minWidth: `${minWidth}px` }),
	...(maxWidth && { maxWidth: `${maxWidth}px` }),
	wordBreak,
}));

export const TableRow = styled(MuiTableRow)<{ isActive?: boolean; whiteSpace?: string }>(
	({ theme, isActive, whiteSpace }) => ({
		borderBottom: `2px solid ${theme.palette.grey[400]}`,
		backgroundColor: isActive ? alpha(theme.palette.grey[300], 0.7) : 'unset',
		...(whiteSpace && { whiteSpace }),
		'&:last-child': {
			borderBottom: 0,
		},
	}),
);

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
	borderBottom: `2px solid ${theme.palette.grey[500]}`,
}));
