import { Rooms } from 'src/redux/types';
import { IQuestionnaireProperty, QuestionnaireStatus } from 'src/types';

export const calculateTotalRooms = (propertyQuestionnaire: IQuestionnaireProperty, rooms: Rooms, key?: string) => {
	console.log('propertyQuestionnaire.property_details.rooms', propertyQuestionnaire.property_details.rooms);

	const arePropertyDetailsCompleted = propertyQuestionnaire.property_details.status === QuestionnaireStatus.DONE;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const totalRooms = Object.keys(rooms).reduce<any[]>((acc, roomKey) => {
		acc.push(
			...(propertyQuestionnaire.property_details.rooms?.[roomKey] ?? []).map((r) => ({
				...r,
				key: r[`${roomKey}_rooms`],
			})),
		);

		return acc;
	}, []);

	console.log('totalRooms', totalRooms);

	if (!arePropertyDetailsCompleted) return null;
	if (key) return totalRooms.filter((r) => r.key === key).length;

	return totalRooms.length;
};
