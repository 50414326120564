/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	addDoc,
	arrayRemove,
	arrayUnion,
	deleteDoc,
	deleteField,
	getDocs,
	query,
	Timestamp,
	updateDoc,
	where,
} from 'firebase/firestore';
import {
	getInvitationMetadata,
	getInvitationUsers,
	getTransactionOverview,
	getTransactionParticipants,
	getTransactionSummary,
	InvitationRole,
} from 'src/api';
import {
	Log,
	FRoles,
	InvitationStatus,
	ObjectWithoutNull,
	TransactionOfferStatusEnum,
	TransactionOnboarding,
	LogTypeEnum,
} from 'src/types';
import {
	TransactionSide,
	TransactionRole,
	TransactionOnboardingNotificationAction,
} from 'src/pages/transactions/types';
import {
	getTransaction as getTransactionUtil,
	getTransactionInvitationRef,
	getTransactionOffer,
	getTransactionOfferRef,
	getTransactionRef,
	getUserRef,
	getPropertyRef,
} from 'src/utils/firebase';
import {
	transactionInvitationsCollection,
	transactionOffersCollection,
	transactionsCollection,
} from 'src/constants/firestore';
import { createTransactionOffer, makeTransactionOffersFrozenByUserId } from 'src/utils/transaction-offer';
import { LocalUser as InvitationLocalUser } from 'src/components/InviteModal/types';
import { transactionPartieSidesKeys } from 'src/pages/transactions/constants';
import {
	getTransactionOnboardingLatestStep,
	getTransactionOnboardingModalName,
	makeTransactionAndOffersFrozen,
} from 'src/utils/transaction';
import { syncPropertyOnboarding } from 'src/api/property-onboarding';
import { findPropertyOnboarding } from 'src/utils/firebase/property-onboarding';
import acceptTransactionInvitationApi, {
	Payload as AcceptTransactionInvitationPayload,
} from 'src/api/invitation/accept-transaction-invitation';
import rejectTransactionInvitationApi, {
	Payload as RejectTransactionInvitationPayload,
} from 'src/api/invitation/reject-transaction-invitation';
import removeTransactionPlaceholderApi, {
	Payload as RemoveTransactionPlaceholderPayload,
} from 'src/api/transaction/remove-placeholder';
import uploadPlaceholderVerificationDocumentApi, {
	Payload as UploadPlaceholderVerificationDocumentPayload,
} from 'src/api/transaction/upload-placeholder-verification-document';
import selectTransactionTitleApi, {
	Payload as SelectTransactionTitlePayload,
} from 'src/api/transaction/select-transaction-title';
import abandonTransactionApi from 'src/api/transaction/abandon';
import askTitleSelectionHelpApi from 'src/api/transaction/ask-title-selection-help';
import { findAllTransactionOnboardings, findTransactionOnboarding } from 'src/utils/firebase/transaction-onboarding';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import syncTransaction from 'src/api/transaction/sync';
import { getSortingComparator } from 'src/utils/common';
import { labelByLogEvent } from 'src/components/TransactionSummary/constants';
import transactionOnboardingNotificationApi from 'src/api/transaction-onboarding/notification';
import getTransactionTrail from 'src/api/transaction/get-transaction-trail';
import { checkIsLogSpecificType } from 'src/utils/log';
import getTransactionTasks, { GetTransactionTasksResponse } from 'src/api/transaction/get-transaction-tasks';
import getTransactionTracker from 'src/api/transaction/get-transaction-tracker';
import { transactionState } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import { dispatch, RootState } from '../store';
import {
	IExtendedTransactionParticipants,
	ITransactionOverview,
	ITransactionSummary,
	ITransactionState,
	TransactionParticipantsSides,
	ITransactionInvitationMetadata,
	ITransactionTracker,
} from '../types';
import { selectUser } from './auth';
import { selectProperty } from './property';
import { openModal } from './modal';
import { getTransactionQuestionnaire } from './transactionQuestionnaire';
import { getQuestionnairePermissionsThunk } from './permissions';

export const selectTransactionSlice = (state: RootState) => state.transaction as ObjectWithoutNull<ITransactionState>;
export const selectTransactionSummary = (state: RootState) => state.transaction.summary;
export const selectTransactionTracker = (state: RootState) => state.transaction.tracker;
export const selectTransactionTrail = (state: RootState) => state.transaction.trail;

export const selectTransactionOverviewField = (state: RootState) => state.transaction.transaction;
export const selectTransactionOverviewLoading = (state: RootState) => state.transaction.transaction.isLoading;
export const selectTransactionOverview = (state: RootState) =>
	state.transaction.transaction.data as ITransactionOverview;

export const selectTransactionParticipantsField = (state: RootState) => state.transaction.participants;
export const selectTransactionParticipants = (state: RootState) =>
	state.transaction.participants.data as IExtendedTransactionParticipants;

export const selectTransactionInvitationUsers = (state: RootState) => state.transaction.invitationUsers;
export const selectTransactionInvitationMetadata = (state: RootState) => state.transaction.metadata;

export const selectTransactionTasks = (state: RootState) => state.transaction.tasks;

export const selectTransactionOnboarding = (state: RootState) => state.transaction.onboarding;

const initialState: ITransactionState = {
	currentTab: 'overview',
	onboarding: null,
	transaction: { data: null, isLoading: true },
	participants: { data: null, isLoading: true },
	invitationUsers: { isLoading: false, data: [] },
	summary: { isInitialized: false, data: null },
	metadata: { isInitialized: false, data: { roles: [] } },
	tasks: { isInitialized: false, data: [] },
	trail: { isInitialized: false, data: { list: [] } },
	tracker: { isInitialized: false, data: null },
};

export const getTransactionParticipantsThunk = createAsyncThunk<
	IExtendedTransactionParticipants,
	{ id: string; withLoading?: boolean }
>('transaction/get', async ({ id }) => {
	const { participants, placeholders } = await getTransactionParticipants(id);
	const emptySides = transactionPartieSidesKeys.reduce((acc, key) => {
		acc[key] = [];

		return acc;
	}, {}) as TransactionParticipantsSides;

	const sides = Object.values(participants).reduce<TransactionParticipantsSides>((acc, users) => {
		users.forEach((user) => acc[user.side].push(user));

		return acc;
	}, emptySides);

	dispatch(getTransactionQuestionnaire({ id }));

	return {
		participants: {
			...participants,
			sides,
			all: [
				...participants.agents,
				...participants.buyers,
				...participants.mortgageBrokers,
				...participants.sellers,
				...participants.solicitors,
				...participants.surveyors,
				...participants.tradesPersons,
				...participants.viewers,
			],
		},
		placeholders,
	};
});

export const getTransactionOverviewThunk = createAsyncThunk<
	ITransactionOverview,
	{ id: string; withLoading?: boolean }
>('transaction-overview/get', async ({ id }) => getTransactionOverview(id));

export const getTransactionSummaryThunk = createAsyncThunk<ITransactionSummary, { id: string; withLoading?: boolean }>(
	'transaction-summary/get',
	async ({ id }) => await getTransactionSummary(id),
);

export const getTransactionTrackerThunk = createAsyncThunk<ITransactionTracker, { id: string; withLoading?: boolean }>(
	'transaction-tracker/get',
	async ({ id }) => await getTransactionTracker(id),
);

export const getTransactionInvitationMetadataThunk = createAsyncThunk<
	ITransactionInvitationMetadata,
	{ transactionId: string }
>('transaction-invitation-metadata/get', async ({ transactionId }) => getInvitationMetadata(transactionId));

export const getTransactionInvitationUsers = createAsyncThunk<
	InvitationLocalUser[],
	{ transactionId: string; role: TransactionRole; side: TransactionSide }
>('transaction-invitation-users/get', async ({ transactionId, role, side }) =>
	getInvitationUsers(transactionId, role, side),
);

export const deleteTransactionInvitation = createAsyncThunk<void, { id: string; solicitorRole: FRoles }>(
	'transactionInvitation/delete',
	async ({ id, solicitorRole }, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;

		const querySnapshot = await getDocs(
			query(
				transactionsCollection,
				where('invitationRole', '==', solicitorRole),
				where('transaction', '==', getTransactionRef(id)),
				where('user', '==', getUserRef(user?.uid ?? '')),
			),
		);

		await Promise.all(querySnapshot.docs.map((data) => deleteDoc(getTransactionRef(data.id))));
	},
);

export const makeAnOffer = createAsyncThunk<void, { id: string; price: number; offerId?: string }>(
	'purchase/offer',
	async ({ id, price, offerId }, { getState }) => {
		const state = getState() as RootState;

		const user = selectUser(state);
		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);

		if (!user || !participants) return;

		const transactionRef = getTransactionRef(id);
		const userRef = getUserRef(user.uid ?? '');

		const newOffer = createTransactionOffer({
			fromUser: userRef,
			transaction: transactionRef,
			price,
			...(!transaction.hasSellSideAgent && !transaction.hasSeller && { agreedBy: [userRef] }),
		});

		if (offerId) {
			const { data: offer, ref: offerRef } = await getTransactionOffer(offerId);

			newOffer.toOffer = offerRef;
			newOffer.toUser = getUserRef(offer.fromUser.id);
		}

		const offer = await addDoc(transactionOffersCollection, newOffer);

		await updateDoc(transactionRef, { offers: arrayUnion(offer) });

		if (!transaction.hasSellSideAgent && !transaction.hasSeller) {
			const viewer = participants.viewers.find((v) => v.offer?.id === offerId);

			if (viewer) {
				await updateDoc<unknown>(transactionRef, {
					agreedOffer: offer,
					[`parties.${viewer.uid}.view.buyer`]: deleteField(),
					[`parties.${viewer.uid}.buy.buyer`]: {
						approved: true,
						joinedAt: Timestamp.now(),
						...(viewer.addedBy && { addedBy: getUserRef(viewer.addedBy.uid) }),
						...(viewer.addedAt && { addedAt: new Date(viewer.addedAt) }),
						...(viewer.automatedSequence && { automatedSequence: viewer.automatedSequence }),
					},
				});

				await syncTransaction({ transactionId: transaction.id });

				dispatch(getTransactionQuestionnaire({ id }));
				dispatch(getQuestionnairePermissionsThunk({ propertyId: transaction.propertyId }));
			}
		}

		if (transaction.myOffer) {
			await updateDoc(getTransactionOfferRef(transaction.myOffer.id), { status: TransactionOfferStatusEnum.FROZEN });
		}

		dispatch(getTransactionOverviewThunk({ id }));
		dispatch(getTransactionParticipantsThunk({ id, withLoading: false }));
	},
);

export const setAskPrice = createAsyncThunk<void, { price: number }>(
	'ask-price/set',
	async ({ price }, { getState }) => {
		const state = getState() as RootState;

		const user = selectUser(state);
		const transaction = selectTransactionOverview(state);

		if (!user || !transaction) return;

		const transactionRef = getTransactionRef(transaction.id);

		if (!transaction.askPrice) {
			const askPrice = await addDoc(
				transactionOffersCollection,
				createTransactionOffer({
					fromUser: getUserRef(user.uid),
					price,
					transaction: transactionRef,
				}),
			);

			await updateDoc<unknown>(transactionRef, { askPrice });

			dispatch(getTransactionOverviewThunk({ id: transaction.id }));

			return;
		}

		await updateDoc<unknown>(getTransactionOfferRef(transaction.askPrice.id), { price, rejectedBy: [] });

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
	},
);

export const withdrawOffer = createAsyncThunk<void, { offerId: string }>(
	'offer/withdraw',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;

		const transaction = selectTransactionOverview(state);

		await updateDoc(getTransactionOfferRef(offerId), { status: TransactionOfferStatusEnum.FROZEN });

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const confirmPurchase = createAsyncThunk<void, never>('purchase/confirm', async (_, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);
	const user = selectUser(state);

	if (!user || !transaction || !transaction.agreedOffer) return;

	await updateDoc(getTransactionOfferRef(transaction.agreedOffer.id), { agreedBy: arrayUnion(user.ref) });

	dispatch(getTransactionOverviewThunk({ id: transaction.id }));
	dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
});

export const cancelPurchase = createAsyncThunk<void, never>('purchase/cancel', async (_, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);
	const { participants } = selectTransactionParticipants(state);
	const user = selectUser(state);

	if (!user || !participants || !transaction?.agreedOffer) return;

	const buyer = participants.buyers.find(({ uid }) => uid === user.uid);

	await updateDoc<unknown>(getTransactionRef(transaction.id), {
		[`parties.${user.uid}.view.buyer`]: {
			approved: true,
			joinedAt: Timestamp.now(),
			...(buyer?.addedBy && { addedBy: getUserRef(buyer.addedBy.uid) }),
		},
		[`parties.${user.uid}.buy.buyer`]: deleteField(),
	});

	dispatch(getTransactionOverviewThunk({ id: transaction.id }));
	dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
});

export const makeCounterOffer = createAsyncThunk<void, { offerId: string; price: number }>(
	'purchase/counter-offer',
	async ({ offerId, price }, { getState }) => {
		const state = getState() as RootState;

		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);
		const user = selectUser(state);

		if (!user || !participants || !transaction) return;

		const offer = await getTransactionOffer(offerId);
		const viewer = participants.viewers.find((v) => v.uid === offer.data.fromUser.id);

		if (viewer?.counterOffer) {
			await updateDoc(getTransactionOfferRef(viewer.counterOffer.id), { status: TransactionOfferStatusEnum.FROZEN });
		}

		const counterOffer = await addDoc(
			transactionOffersCollection,
			createTransactionOffer({
				transaction: getTransactionRef(transaction.id),
				price,
				toUser: offer.data.fromUser,
				toOffer: offer.ref,
				fromUser: getUserRef(user.uid),
			}),
		);

		await updateDoc<unknown>(getTransactionRef(transaction.id), { offers: arrayUnion(counterOffer) });
	},
);

export const confirmCounterOffer = createAsyncThunk<void, { offerId: string }>(
	'purchase/confirm-counter-offer',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		const offer = await getTransactionOffer(offerId);

		await updateDoc(offer.ref, { status: TransactionOfferStatusEnum.FROZEN });

		const newOffer = await addDoc(
			transactionOffersCollection,
			createTransactionOffer({
				transaction: getTransactionRef(transaction.id),
				fromUser: getUserRef(user.uid),
				price: offer.data.price,
			}),
		);

		await updateDoc(getTransactionRef(transaction.id), {
			offers: arrayUnion(newOffer),
		});
	},
);

export const rejectOffer = createAsyncThunk<void, { offerId: string }>(
	'offer/reject',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		await updateDoc(getTransactionOfferRef(offerId), { status: TransactionOfferStatusEnum.REJECTED });

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const rejectAskPrice = createAsyncThunk<void, { id: string }>(
	'ask-price/reject',
	async ({ id }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		await updateDoc<unknown>(getTransactionOfferRef(id), { rejectedBy: arrayUnion(user.ref) });

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const acceptOffer = createAsyncThunk<void, { offerId: string }>(
	'offer/accept',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		const viewer = participants.viewers.find((v) => v.offer?.id === offerId);

		if (!viewer?.offer) return;

		const transactionRef = getTransactionRef(transaction.id);

		const offerRef = getTransactionOfferRef(viewer.offer.id);

		// POSSIBLE SOME PARTIE DATA MISSING HERE
		await Promise.all([
			updateDoc<unknown>(transactionRef, {
				agreedOffer: offerRef,
				askPrice: offerRef,
				[`parties.${viewer.uid}.view.buyer`]: deleteField(),
				[`parties.${viewer.uid}.buy.buyer`]: {
					approved: true,
					joinedAt: Timestamp.now(),
					...(viewer.addedBy && { addedBy: getUserRef(viewer.addedBy.uid) }),
					...(viewer.addedAt && { addedAt: new Date(viewer.addedAt) }),
					...(viewer.automatedSequence && { automatedSequence: viewer.automatedSequence }),
				},
			}),
			updateDoc(getTransactionOfferRef(offerId), {
				status: TransactionOfferStatusEnum.AGREED,
				agreedBy: arrayUnion(getUserRef(user.uid)),
			}),
		]);

		await syncTransaction({ transactionId: transaction.id });

		dispatch(getTransactionQuestionnaire({ id: transaction.id }));
		dispatch(getQuestionnairePermissionsThunk({ propertyId: transaction.propertyId }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const acceptCounteroffer = createAsyncThunk<void, { offerId: string }>(
	'counteroffer/accept',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		const transactionRef = getTransactionRef(transaction.id);

		const viewer = participants.viewers.find((v) => v.counterOffer?.id === offerId);

		if (!viewer?.counterOffer) return;

		if (viewer.offer) {
			await updateDoc(getTransactionOfferRef(viewer.offer.id), { status: TransactionOfferStatusEnum.FROZEN });
		}

		const offer = await addDoc(
			transactionOffersCollection,
			createTransactionOffer({
				fromUser: getUserRef(user.uid),
				transaction: transactionRef,
				price: viewer.counterOffer.price,
			}),
		);

		await Promise.all([
			updateDoc(getTransactionOfferRef(offerId), { status: TransactionOfferStatusEnum.ACCEPTED }),
			updateDoc(transactionRef, { offers: arrayUnion(offer) }),
		]);

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const confirmRejectedOffer = createAsyncThunk<void, { offerId: string }>(
	'rejected-offer/confirm',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		await updateDoc(getTransactionOfferRef(offerId), { status: TransactionOfferStatusEnum.FROZEN });

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const confirmAcceptedOffer = createAsyncThunk<void, { offerId: string }>(
	'accepted-offer/confirm',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		await updateDoc(getTransactionOfferRef(offerId), { agreedBy: arrayUnion(getUserRef(user.uid)) });

		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
	},
);

export const viewCounterOffer = createAsyncThunk<void, { offerId: string }>(
	'counter-offer/view',
	async ({ offerId }, { getState }) => {
		const state = getState() as RootState;
		const transaction = selectTransactionOverview(state);
		const user = selectUser(state);

		if (!user || !transaction) return;

		await updateDoc(getTransactionOfferRef(offerId), { viewedBy: arrayUnion(getUserRef(user.uid)) });

		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
	},
);

export const rejectCounterOffer = createAsyncThunk<void, { offerId: string }>(
	'purchase/reject-counter-offer',
	async ({ offerId }, { getState }) => {
		const {
			auth: { user },
			transaction: { transaction },
		} = getState() as RootState;

		if (!user || !transaction) return;

		const offer = await getTransactionOffer(offerId);

		await updateDoc(offer.ref, { status: TransactionOfferStatusEnum.FROZEN });
	},
);

export const delistTransaction = createAsyncThunk<void, string>('transaction/delist', async (id) => {
	try {
		const transaction = await getTransactionUtil(id);

		await Promise.all([
			await updateDoc<unknown>(transaction.ref, { state: transactionState.defunct }),
			updateDoc<unknown>(transaction.data.property, { onSale: false }),
		]);
	} catch (e) {
		console.log(e);
	}
});

export const deleteTransaction = createAsyncThunk<void, string>('transaction/delete', async (id) => {
	try {
		const transaction = await getTransactionUtil(id);

		await Promise.all([
			await deleteDoc(transaction.ref),
			updateDoc<unknown>(transaction.data.property, { onSale: false, transaction: null }),
		]);
	} catch (e) {
		console.log(e);
	}
});

export const dropFromTransaction = createAsyncThunk<void, string>('transaction/drop', async (id, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);
	const { participants } = selectTransactionParticipants(state);
	const user = selectUser(state);
	const property = selectProperty(state);

	if (!user || !participants || !transaction) return;

	const transactionRef = getTransactionRef(id);
	const newBuyers = participants.buyers.filter(({ uid: userId }) => userId !== user.uid);

	await makeTransactionOffersFrozenByUserId(transaction.offers, user.uid);

	if (transaction.agreedOffer) {
		await updateDoc(getTransactionOfferRef(transaction.agreedOffer.id), {
			agreedBy: arrayRemove(getUserRef(user.uid)),
		});
	}

	await Promise.all([
		updateDoc<unknown>(transactionRef, {
			[`parties.${user?.uid}`]: deleteField(),
			...(!newBuyers.length && { agreedOffer: deleteField() }),
		}),
		syncPropertyOnboarding(transaction.property.id, property?.onboarding?.activeSide ?? 'sell'),
	]);
});

export const frozeTransaction = createAsyncThunk<void, string>('transaction/froze', async (id, { getState }) => {
	const {
		auth: { user },
		transaction: { transaction },
	} = getState() as RootState;

	if (!user || !transaction) return;

	const transactionRef = getTransactionRef(id);

	await makeTransactionAndOffersFrozen(id);

	await updateDoc<unknown>(transactionRef, { state: transactionState.defunct });
});

export const dropFromProperty = createAsyncThunk<void, string>('property/drop', async (id, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return;

	const propertyRef = getPropertyRef(id);
	const onboarding = await findPropertyOnboarding(user.uid, id);

	await Promise.all([
		updateDoc<unknown>(propertyRef, {
			viewers: arrayRemove(user?.ref),
		}),
		onboarding && deleteDoc(onboarding.ref),
	]);
});

export const confirmBuyer = createAsyncThunk<void, { id: string; userId: string; price: number }>(
	'buyer/confirm',
	async ({ id, userId, price }, { getState }) => {
		const state = getState() as RootState;

		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);
		const user = selectUser(state);

		if (!user || !participants || !transaction) return;

		const userRef = getUserRef(userId);
		const transactionRef = getTransactionRef(id);

		if (!transaction.agreedOffer) {
			const offerRef = await addDoc(
				transactionOffersCollection,
				createTransactionOffer({
					timestamp: Timestamp.now(),
					transaction: transactionRef,
					fromUser: getUserRef(user.uid),
					agreedBy: [userRef],
					viewedBy: [userRef],
					price,
					status: TransactionOfferStatusEnum.AGREED,
				}),
			);

			await updateDoc<unknown>(transactionRef, { agreedOffer: offerRef });
		}

		const viewer = participants.viewers.find(({ uid }) => uid === userId);

		if (!viewer) return;

		await Promise.all([
			updateDoc<unknown>(transactionRef, {
				[`parties.${userId}.view.buyer`]: deleteField(),
				[`parties.${userId}.buy.buyer`]: {
					approved: true,
					joinedAt: Timestamp.now(),
					...(viewer.addedBy && { addedBy: getUserRef(viewer.addedBy.uid) }),
					...(viewer.addedAt && { addedAt: new Date(viewer.addedAt) }),
					...(viewer.automatedSequence && { automatedSequence: viewer.automatedSequence }),
				},
			}),
		]);

		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	},
);

export const removeTransactionPartie = createAsyncThunk<
	void,
	{ id: string; userId: string; side: TransactionSide; role: TransactionRole }
>('transaction-partie/remove', async ({ id, userId, side, role }, { getState }) => {
	try {
		const state = getState() as RootState;

		const transaction = selectTransactionOverview(state);
		const { participants } = selectTransactionParticipants(state);

		if (!transaction || !participants) return;

		const newBuyers = participants.buyers.filter((u) => u.uid !== userId);
		const shouldDeleteAgreedOffer = role === 'buyer' && !newBuyers.length;
		const participant = participants.all.find((p) => p.uid === userId);

		if (transaction.agreedOffer) {
			await updateDoc(getTransactionOfferRef(transaction.agreedOffer.id), {
				agreedBy: arrayRemove(getUserRef(userId)),
			});
		}

		await Promise.all([
			makeTransactionOffersFrozenByUserId(transaction.offers, userId),
			updateDoc<unknown>(getTransactionRef(id), {
				[`parties.${userId}.${side}.${role}`]: deleteField(),
				...(shouldDeleteAgreedOffer && { agreedOffer: deleteField() }),
			}),
			participant?.placeholderId &&
				syncTransaction({ transactionId: id, placeholderId: participant.placeholderId, removedUserId: userId }),
		]);

		dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
	} catch (e) {
		console.log(e);
	}
});

export const makeBuyer = createAsyncThunk<
	void,
	{ transactionInvitationId: string; transactionId: string; userId: string; price: number }
>('purchase/make-buyer', async ({ transactionInvitationId, transactionId, userId, price }) => {
	const transactionInvitationRef = getTransactionInvitationRef(transactionInvitationId);
	const transactionRef = getTransactionRef(transactionId);

	await updateDoc<unknown>(transactionRef, {
		[`parties.prospective_buyers.${userId}`]: { purchasePrice: price, approved: true },
	});
	await updateDoc(transactionInvitationRef, { status: InvitationStatus.OFFER_SENT });
});

export const withdrawUserPurchase = createAsyncThunk<void, string>('purchase/withdraw', async (id, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;
	const docRef = getTransactionRef(id);

	const { docs: transactionInvitations } = await getDocs(
		query(
			transactionInvitationsCollection,
			where('transaction', '==', docRef),
			where('user', '==', user?.ref),
			where('invitationRole', '==', InvitationRole.buyer),
		),
	);

	await Promise.all(transactionInvitations.map((item) => deleteDoc(item.ref)));
	await updateDoc(docRef, {
		[`parties.prospective_buyers.${user?.uid || ''}`]: deleteField(),
	});
});

export const pinTransaction = createAsyncThunk<void, { id: string; isPinned: boolean }>(
	'transaction/pin-unpin',
	async ({ id, isPinned }, { getState }) => {
		const {
			auth: { user },
		} = getState() as RootState;

		if (!user) return;

		const transaction = getTransactionRef(id);

		await updateDoc<unknown>(user.ref, {
			'settings.pinnedTransactions': isPinned ? arrayRemove(transaction) : arrayUnion(transaction),
		});
	},
);

export const processTransactionOnboarding = createAsyncThunk<
	(TransactionOnboarding & { id: string }) | null,
	{ transactionId: string }
>('transaction-onboarding/process', async ({ transactionId }, { getState, rejectWithValue }) => {
	try {
		const state = getState() as RootState;

		const user = selectUser(state);
		const transaction = selectTransactionOverview(state);

		if (!user) return rejectWithValue('Unauthorized');

		const onboardingSnapshot = await findTransactionOnboarding(user.uid, transactionId);

		if (!onboardingSnapshot || !onboardingSnapshot.exists()) return null;

		const onboardingRef = onboardingSnapshot.ref;
		const onboarding = onboardingSnapshot.data();

		if (!onboardingRef || !onboarding) return null;

		const latestOnboardingStep = getTransactionOnboardingLatestStep(onboarding);
		const latestOnboardingStepModalName =
			latestOnboardingStep && getTransactionOnboardingModalName(latestOnboardingStep);

		if (latestOnboardingStepModalName) {
			dispatch(
				openModal({
					name: latestOnboardingStepModalName,
					payload: { side: transaction.isBuyer ? 'buy' : transaction.isSeller ? 'sell' : 'ancillary' },
				}),
			);
		} else handleCloseModal();

		return { ...onboarding, id: onboardingSnapshot.id };
	} catch (e) {
		console.log('error', e);

		return rejectWithValue(e);
	}
});

export const updateTransactionOnboarding = createAsyncThunk<
	TransactionOnboarding,
	Omit<Partial<TransactionOnboarding>, 'userRef' | 'transactionRef'>,
	{ rejectValue: ValidationErrors | string }
>('transaction-onboarding/update', async (payload, { getState, rejectWithValue }) => {
	const state = getState() as RootState;

	const user = selectUser(state);
	const { data: transaction } = selectTransactionOverviewField(state);

	if (!user || !transaction) return rejectWithValue('Unauthorized');

	const onboardingSnapshot = await findTransactionOnboarding(user.uid, transaction.id);

	if (!onboardingSnapshot || !onboardingSnapshot.exists()) return rejectWithValue('Unauthorized');

	const onboardingRef = onboardingSnapshot.ref;
	const onboarding = onboardingSnapshot.data();

	if (!onboardingRef || !onboarding) return rejectWithValue('Onboarding not found');

	const updatedOnboarding: TransactionOnboarding = { ...onboarding, ...payload };

	await updateDoc(onboardingRef, updatedOnboarding);

	const latestOnboardingStep = getTransactionOnboardingLatestStep(updatedOnboarding);
	const latestOnboardingStepModalName = latestOnboardingStep && getTransactionOnboardingModalName(latestOnboardingStep);

	if (latestOnboardingStepModalName) dispatch(openModal({ name: latestOnboardingStepModalName }));
	else handleCloseModal();

	return updatedOnboarding;
});

export const updateAllTransactionOnboardings = createAsyncThunk<
	Array<(TransactionOnboarding & { id: string }) | null>,
	Omit<Partial<TransactionOnboarding>, 'userRef' | 'transactionRef'>,
	{ rejectValue: ValidationErrors | string }
>('transaction-onboardings/update-all', async (payload, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return [];

	const allOnboardings = await findAllTransactionOnboardings(user.uid);

	return await Promise.all(
		allOnboardings.map(async (onboardingSnapshot) => {
			if (!onboardingSnapshot.exists()) return null;

			const onboarding = onboardingSnapshot.data();

			const updatedOnboarding: TransactionOnboarding & { id: string } = {
				id: onboardingSnapshot.id,
				...onboarding,
				...payload,
			};
			await updateDoc(onboardingSnapshot.ref, updatedOnboarding);

			return updatedOnboarding;
		}),
	);
});

export const acceptTransactionInvitation = createAsyncThunk<void, AcceptTransactionInvitationPayload>(
	'transaction-invitation/accept',
	async (payload) => {
		await acceptTransactionInvitationApi(payload);
	},
);

export const getTransactionTrailThunk = createAsyncThunk<Log[]>('transaction/get-trail', async (_, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);

	const { trail } = await getTransactionTrail(transaction.id);

	return trail;
});

export const getTransactionTasksThunk = createAsyncThunk<
	GetTransactionTasksResponse,
	{ withLoading?: boolean } | undefined
>('transaction/get-tasks', async (_, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);

	const response = await getTransactionTasks(transaction.id);

	return response;
});

export const rejectTransactionInvitation = createAsyncThunk<void, RejectTransactionInvitationPayload>(
	'transaction-invitation/reject',
	async (payload) => {
		await rejectTransactionInvitationApi(payload);
	},
);

export const removeTransactionPlaceholder = createAsyncThunk<
	void,
	Omit<RemoveTransactionPlaceholderPayload, 'transactionId'>
>('transaction-placeholder/remove', async (payload, { getState }) => {
	const state = getState() as RootState;

	const transaction = selectTransactionOverview(state);

	await removeTransactionPlaceholderApi({ id: payload.id, transactionId: transaction.id });
});

export const uploadPlaceholderVerificationDocument = createAsyncThunk<
	void,
	Omit<UploadPlaceholderVerificationDocumentPayload, 'type'>
	// eslint-disable-next-line consistent-return
>('transaction-document/upload-verification', async (payload, { rejectWithValue }) => {
	try {
		await uploadPlaceholderVerificationDocumentApi(payload);
	} catch (e) {
		return rejectWithValue(e);
	}
});

export const selectTransactionTitle = createAsyncThunk<void, Omit<SelectTransactionTitlePayload, 'transactionId'>>(
	'transaction-title/remove',
	// eslint-disable-next-line consistent-return
	async (payload, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState;

			const transaction = selectTransactionOverview(state);

			await selectTransactionTitleApi({ transactionId: transaction.id, title: payload.title, force: payload.force });
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const abandonTransaction = createAsyncThunk<void>(
	'transaction/abandon',
	// eslint-disable-next-line consistent-return
	async (_, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState;

			const transaction = selectTransactionOverview(state);

			await abandonTransactionApi({ transactionId: transaction.id });
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const askTitleSelectionHelp = createAsyncThunk<void>(
	'transaction-title/ask-help',
	// eslint-disable-next-line consistent-return
	async (_, { getState, rejectWithValue }) => {
		try {
			const state = getState() as RootState;

			const property = selectProperty(state);

			await askTitleSelectionHelpApi({ propertyId: property.id });
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export const transactionOnboardingNotification = createAsyncThunk<
	{ message: string } | undefined,
	{ id: string; action: TransactionOnboardingNotificationAction },
	{ rejectValue: ValidationErrors | string }
>(
	'transaction-onboarding-notification/send',
	async ({ id, action }) => await transactionOnboardingNotificationApi(id, action),
);

export const skipGiftorWelcomeModalThunk = createAsyncThunk<void>(
	'giftor-welcome-modal/skip',
	async (_, { getState }) => {
		try {
			const state = getState() as RootState;
			const transaction = selectTransactionOverview(state);
			const user = selectUser(state);

			if (!user || !transaction) return;

			await updateDoc<unknown>(getTransactionRef(transaction.id), {
				[`parties.${user.uid}.buy.buyer.isWelcomeModalSkipped`]: true,
			});

			dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		} catch (e) {
			console.log(e);

			throw e;
		}
	},
);

const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		clearTransaction: (state) => {
			state.transaction = { data: null, isLoading: true, error: null };
			state.participants = { data: null, isLoading: true, error: null };
			state.invitationUsers = { data: [], isLoading: true, error: null };
			state.summary = { data: null, isInitialized: false, error: null };
			state.metadata = { data: { roles: [] }, isInitialized: false, error: null };
			state.trail = { data: { list: [] }, isInitialized: false, error: null };
			state.onboarding = null;
			state.currentTab = 'overview';
		},
		clearTrail: (state) => {
			state.trail = { data: { list: [] }, isInitialized: false, error: null };
		},
		setCurrentTab: (state, { payload }: PayloadAction<string>) => {
			state.currentTab = payload;
		},
		setParticipantsLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.participants.isLoading = payload;
		},
		setIsTrackerLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.tracker.isInitialized = !payload;
		},
		sortTrail: (state, { payload: { order, orderBy } }: PayloadAction<{ order: 'asc' | 'desc'; orderBy: string }>) => {
			state.trail.data.order = order;
			state.trail.data.orderBy = orderBy;
			state.trail.data.list = state.trail.data.list.slice().sort(
				getSortingComparator(order, (log) => {
					if (orderBy === 'timestamp') return new Date(log.createdAt);
					if (orderBy === 'finishedAt') return log.finishedAt ? new Date(log.finishedAt) : null;
					if (orderBy === 'status') return log.status;
					if (orderBy === 'type') return log.type;
					if (orderBy === 'event') return labelByLogEvent[log.event];
					if (orderBy === 'documents' && checkIsLogSpecificType(log, LogTypeEnum.JOB)) {
						return log.metadata.documents?.[0];
					}

					return log.metadata?.[orderBy];
				}),
			);
		},
		removeTask: (state, { payload: { taskId, userId } }: PayloadAction<{ taskId: string; userId: string }>) => {
			if (!state.tasks.data) return state;

			state.tasks.data = state.tasks.data.map((t) => {
				if (t.user.id === userId) return { ...t, tasks: t.tasks.filter((task) => task.id !== taskId) };

				return t;
			});

			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getTransactionOverviewThunk.pending, (state, { meta }) => {
			if (meta.arg.withLoading) state.transaction.isLoading = true;
		});
		builder.addCase(getTransactionOverviewThunk.fulfilled, (state, { payload }) => {
			state.transaction.data = payload;
			state.transaction.isLoading = false;
		});
		builder.addCase(getTransactionOverviewThunk.rejected, (state, { error }) => {
			state.transaction.error = error;
			state.transaction.isLoading = false;
		});

		builder.addCase(getTransactionParticipantsThunk.pending, (state, { meta }) => {
			if (meta.arg.withLoading) state.participants.isLoading = true;
		});
		builder.addCase(getTransactionParticipantsThunk.fulfilled, (state, { payload }) => {
			state.participants.data = payload;
			state.participants.isLoading = false;
		});
		builder.addCase(getTransactionParticipantsThunk.rejected, (state, { error }) => {
			state.participants.error = error;
			state.participants.isLoading = false;
		});

		builder.addCase(getTransactionInvitationMetadataThunk.pending, (state) => {
			state.metadata.isInitialized = false;
		});
		builder.addCase(getTransactionInvitationMetadataThunk.fulfilled, (state, { payload }) => {
			state.metadata.data = payload;
			state.metadata.isInitialized = true;
		});
		builder.addCase(getTransactionInvitationMetadataThunk.rejected, (state, { error }) => {
			state.metadata.error = error;
			state.metadata.isInitialized = false;
		});

		builder.addCase(getTransactionInvitationUsers.pending, (state) => {
			state.invitationUsers.isLoading = true;
		});
		builder.addCase(getTransactionInvitationUsers.fulfilled, (state, { payload }) => {
			state.invitationUsers.isLoading = false;
			state.invitationUsers.data = payload;
		});
		builder.addCase(getTransactionInvitationUsers.rejected, (state, { error }) => {
			state.invitationUsers.error = error;
			state.invitationUsers.isLoading = false;
			state.invitationUsers.data = [];
		});

		builder.addCase(getTransactionSummaryThunk.fulfilled, (state, { payload }) => {
			state.summary.data = payload;
			state.summary.isInitialized = true;
		});
		builder.addCase(getTransactionSummaryThunk.rejected, (state, { error }) => {
			state.summary.error = error;
			state.summary.isInitialized = true;
			state.summary.data = null;
		});

		builder.addCase(getTransactionTrackerThunk.pending, (state, { meta }) => {
			if (meta.arg.withLoading) state.tracker.isInitialized = false;
		});
		builder.addCase(getTransactionTrackerThunk.fulfilled, (state, { payload }) => {
			state.tracker.data = payload;
			state.tracker.isInitialized = true;
		});
		builder.addCase(getTransactionTrackerThunk.rejected, (state, { error }) => {
			state.tracker.error = error;
			state.tracker.isInitialized = true;
			state.tracker.data = null;
		});

		builder.addCase(acceptTransactionInvitation.pending, (state) => {
			state.participants.isLoading = true;
		});
		builder.addCase(acceptTransactionInvitation.fulfilled, (state) => {
			state.participants.isLoading = false;
		});
		builder.addCase(acceptTransactionInvitation.rejected, (state) => {
			state.participants.isLoading = false;
		});

		builder.addCase(uploadPlaceholderVerificationDocument.pending, (state) => {
			state.participants.isLoading = true;
		});
		builder.addCase(uploadPlaceholderVerificationDocument.rejected, (state) => {
			state.participants.isLoading = false;
		});

		builder.addCase(rejectTransactionInvitation.pending, (state) => {
			state.participants.isLoading = true;
		});
		builder.addCase(rejectTransactionInvitation.fulfilled, (state) => {
			state.participants.isLoading = false;
		});
		builder.addCase(rejectTransactionInvitation.rejected, (state) => {
			state.participants.isLoading = false;
		});

		builder.addCase(removeTransactionPlaceholder.pending, (state) => {
			state.participants.isLoading = true;
		});

		builder.addCase(processTransactionOnboarding.fulfilled, (state, { payload }) => {
			state.onboarding = payload;
		});

		builder.addCase(getTransactionTrailThunk.pending, (state) => {
			state.trail.isInitialized = false;
		});
		builder.addCase(getTransactionTrailThunk.fulfilled, (state, { payload }) => {
			state.trail.isInitialized = true;
			state.trail.data.list = payload;
		});
		builder.addCase(getTransactionTrailThunk.rejected, (state, { error }) => {
			state.trail.isInitialized = true;
			state.trail.error = error;
		});

		builder.addCase(getTransactionTasksThunk.pending, (state, action) => {
			if (action.meta.arg?.withLoading) state.tasks.isInitialized = false;
		});
		builder.addCase(getTransactionTasksThunk.fulfilled, (state, { payload }) => {
			state.tasks.isInitialized = true;
			state.tasks.data = payload;
		});
		builder.addCase(getTransactionTasksThunk.rejected, (state, { error }) => {
			state.tasks.isInitialized = true;
			state.tasks.error = error;
		});
	},
});

export default transactionSlice.reducer;

export const {
	clearTransaction,
	setParticipantsLoading,
	setCurrentTab: setCurrentTransactionTab,
	sortTrail: sortTransactionTrail,
	clearTrail: clearTransactionTrail,
	setIsTrackerLoading,
	removeTask,
} = transactionSlice.actions;
