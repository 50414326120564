import { Box, styled } from '@mui/material';
import { PropertyGeneralAddressCard } from '../../styled';

export const TrackerSectionWrapper = styled(Box)<{ withTracker?: boolean }>(({ withTracker }) => ({
	display: 'grid',
	gridTemplateColumns: withTracker ? '900px 990px' : '1270px',
	gap: '30px',
	paddingBottom: '30px',

	'@media only screen and (max-width: 2800px)': {
		gridTemplateColumns: '990px',
	},
}));

export const TrackerWrapper = styled(PropertyGeneralAddressCard)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
export const NextActionWrapper = styled(PropertyGeneralAddressCard)({ overflowY: 'auto' });
