import { noop } from 'lodash';
import { ProviderContext } from 'notistack';
import resendTransactionInvitation, {
	Payload as ResendTransactionInvitationPayload,
} from 'src/api/invitation/resend-transaction-invitation';
import { IInviteCardMenuOption, LocalUser } from 'src/components/InviteCard/types';
import modals from 'src/constants/modals';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import { downloadUserProofOfId } from 'src/utils/storage';
import { UserData } from 'src/api/auth/@user-data';
import { getTransactionParticipantsThunk, removeTransactionPlaceholder } from 'src/redux/slices/transaction';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { IExtendedQuestionnaireTransaction } from 'src/types';
import cancelTransactionInvitation from 'src/api/invitation/cancel-transaction-invitation';
import UploadProofOfIdOption from './components/UploadProofOfIdOption';

type Alert = ProviderContext['enqueueSnackbar'];

export const getResendInvitationMenuOption = (
	payload: ResendTransactionInvitationPayload,
	alert: Alert,
): IInviteCardMenuOption => ({
	label: 'Resend invitation',
	onClick: async () => {
		try {
			const { message } = await resendTransactionInvitation(payload);

			dispatch(getTransactionParticipantsThunk({ withLoading: true, id: payload.transactionId }));

			alert(message);
		} catch (e) {
			console.log('e', e);
			if (e instanceof Error) {
				alert(e.message, { variant: 'error' });
			}
		}
	},
});

export const getCancelInvitationMenuOption = (
	payload: ResendTransactionInvitationPayload,
	alert: Alert,
): IInviteCardMenuOption => ({
	label: 'Cancel invitation',
	onClick: async () => {
		try {
			await cancelTransactionInvitation(payload);

			dispatch(getTransactionParticipantsThunk({ withLoading: true, id: payload.transactionId }));

			alert('Invitation successfully canceled');
		} catch (e) {
			console.log('e', e);
			if (e instanceof Error) {
				alert(e.message, { variant: 'error' });
			}
		}
	},
});

export const getVerificationIdMenuOptions = (
	isAdmin: boolean,
	user: LocalUser,
	alert: Alert,
): IInviteCardMenuOption[] => {
	const options: IInviteCardMenuOption[] = [];

	if ((user.canDownloadProofOfId || isAdmin) && user.documents?.verification) {
		options.push({
			label: 'Download Proof of ID',
			onClick: async () => {
				const proof = await downloadUserProofOfId(
					`${user.givenNames} ${user.lastNames}`,
					user.documents?.verification ?? `${user.uid}/verification-pdf.pdf`,
				);

				if (!proof) alert('No Proof of ID found!', { variant: 'error' });
			},
		});
	}

	return options;
};

export const getPlaceholderMenuOptions = (
	transactionId: string,
	currentUser: UserData,
	user: LocalUser,
): IInviteCardMenuOption[] => {
	const options: IInviteCardMenuOption[] = [];

	if (user.isPlaceholder) {
		if (user.addedBy?.uid === currentUser.uid || currentUser.isAdmin) {
			options.push({
				label: 'Edit',
				onClick: async () => {
					dispatch(
						openModal({ name: modals.invitation, payload: { placeholderId: user.placeholderId, isEdit: true } }),
					);
				},
			});
		}

		options.push({
			label: 'Invite',
			onClick: async () => {
				dispatch(openModal({ name: modals.invitation, payload: { placeholderId: user.placeholderId } }));
			},
		});

		if ((user.addedBy?.uid === currentUser.uid || currentUser.isAdmin) && !user.isFromTitle) {
			options.push({
				label: 'Remove',
				onClick: async () => {
					await dispatch(removeTransactionPlaceholder({ id: user.placeholderId as string }));
					dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
				},
			});
		}

		if (currentUser.isAdmin && user.placeholderId) {
			options.push({
				label: (args) => <UploadProofOfIdOption placeholderId={user.placeholderId as string} {...args} />,
				onClick: noop,
			});
		}
	}

	return options;
};

export const getQuestionnaireMenuOptions = (
	currentUser: UserData,
	user: LocalUser,
	questionnaire: IExtendedQuestionnaireTransaction,
): IInviteCardMenuOption[] => {
	const isPrincipal = user.role === 'buyer' || user.role === 'seller';
	const options: IInviteCardMenuOption[] = [];
	const personalDetails = questionnaire.personal_details?.[user.uid];

	if (isPrincipal && user.isApproved && personalDetails && currentUser.isAdmin) {
		options.push({
			label: 'Edit details',
			onClick: () => {
				dispatch(openModal({ name: QuestionnaireTransactionEnum.personal_details, payload: { userId: user.uid } }));
			},
		});
	}

	return options;
};
